import React, { useState } from 'react';
import {Tooltip, Box} from "@material-ui/core";
import {FileCopy as CopyIcon, CheckCircleOutlined as CheckedIcon} from "@material-ui/icons";

import { copyToClipboard } from '../../utils/copyToClipboard';

function CopyToClipboard({title, content, iconSize = 'small', style}) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    setIsCopied(true);
    await copyToClipboard(Array.isArray(content) ? content.join(',') : (typeof content === 'string' ? content && content.replaceAll("^\"|\"$", "") : JSON.stringify(content)))
  }

  return (
    <Tooltip title={isCopied ? "Copied" : title}>
      <Box component="span" mx={1} style={{cursor: "pointer"}}>
        {!isCopied && <CopyIcon fontSize={iconSize} onClick={handleCopy} style={style} />}
        {isCopied && <CheckedIcon fontSize={iconSize} style={style} />}
      </Box>
    </Tooltip>
  )
}

export default CopyToClipboard