import React from 'react';
import {Box} from "@material-ui/core";

import SimpleTable from "../../components/table/SimpleTable";
import LoadingButton from "../../components/Button/LoadingButton";
import CopyToClipboard from "../../components/CopyToClipboard";

function FakeShipmentList({shipments, simulation, neededShipments, generateFakeShipments, creating}) {
  const columns = [
    {label: "ID", dataKey: "id", width: 100},
    {label: "Tracking Code", dataKey: "tracking_code", width: 150},
    {label: "Zipcode", dataKey: "dropoff_address.zipcode", width: 100},
  ]

  return (
    <Box>
      <Box>
        <Box component="span">
          Needed: {neededShipments} - Generated: {shipments.length}
        </Box>
        <CopyToClipboard
          title={'Copy all shipment ids'}
          content={shipments.map((a) => a.id)}
          style={{fontSize: '1em'}}
        />
        <LoadingButton
          color="primary"
          variant="contained"
          style={{ marginLeft: 8 }}
          onClick={generateFakeShipments}
          disabled={neededShipments <= 0}
          label="Generate Fake Shipments"
          loading={creating}
        />
      </Box>
      <Box>
        <SimpleTable
          rowCount={shipments.length}
          rows={shipments}
          columns={columns}
        />
      </Box>
    </Box>
  )
}

export default FakeShipmentList;