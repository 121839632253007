import { atom, selector, selectorFamily } from 'recoil';
import produce from 'immer';
import _ from "lodash";
import { SAMEDAY_CLIENT_ID, NEXTDAY_CLIENT_ID, serviceLevels } from '../../constants/config';
import { Simulate } from 'react-dom/test-utils';

export const filtersListState = atom({
  key: 'pricing_filters_list',
  default: {
    page: 0,
    size: 25,
    order_by: 'created',
    desc: true,
  },
});

export const simulationsState = atom({
  key: 'pricing_simulations',
  default: [],
});

export const simulatingState = atom({
  key: 'pricing_simulating',
  default: {},
});

export const routingProblemsState = atom({
  key: 'pricing_routing_problems',
  default: [],
});

export const currentRoutingProblemIndexState = atom({
  key: 'pricing_current_problem_index',
  default: 0,
});

export const viewportState = atom({
  key: 'pricing_viewport',
  default: {
    latitude: 32.54895,
    longitude: -118.79886,
    zoom: 9,
  }
})

export const currentRoutingProblemState = selector({
  key: 'pricing_current_Routing_problem',
  get: ({get}) => {
    const routingProblems = get(routingProblemsState);
    const index = get(currentRoutingProblemIndexState);

    if (!routingProblems 
        || routingProblems.length < 0 
        || index === undefined
        || routingProblems.length < (index + 1)) {
      return null;
    }

    return routingProblems[index];
  },
  set: ({get, set}) => {

  }
});

export const pickupAddressState = selector({
  key: 'pricing_pickup_address',
  get: ({get}) => {
    const inputData = get(inputDataState);
    const {address} = inputData;

    return Object.keys(address)
      .filter(key => !['lat', 'lng'].includes(key))
      .map(key => address[key])
      .filter(value => !!value)
      .join(", ");
  },
  set: ({ get, set, reset }, data) => {
    const inputData = get(inputDataState);
    const {address} = inputData;
    const {field, value} = data;

    set(inputDataState, {...inputData, address: {...address, [field]: value}});
  },
});

export const defaultInputData = {
  zipcode_list: {},
  address: {
    street: "",
    city: "",
    state: "",
    zipcode: "",
  },
  shipments_count: 0,
  vehicle_capacity: 0,
  radius: [0, 0, 0],
  zone_shipments: [],
  routing_problem_ids: [],
  use_zipcode: false,
  instance: 1,
  client_id: NEXTDAY_CLIENT_ID,
  pricing: {
    minimum: 55,
    box_rate: 2.5,
    mileage_rate: 0.75,
    extra_box_rate: 0,
    mileage_credit: 0.55
  },
  distance_type: 'FLYING_DISTANCE',
  distance_point: 'CENTER',
  use_zipcode_csv: false
}

export const instanceState = selector({
  key: 'pricing_routing_instance',
  get: ({get}) => { 
    const inputData = get(inputDataState);
    return inputData.instance;
  },
  set: ({get, set}, value) => {
    const inputData = get(inputDataState);
    set(inputDataState, produce(inputData, (draft) => {
      try {
        draft.instance = parseInt(value.trim());
      } catch (e) {
        draft.instance = 1;
      }
    }))
  }
});

export const pricingState = selector({
  key: 'pricing_state',
  get: ({get}) => {
    const inputData = get(inputDataState);
    return inputData.pricing;
  },
  set: ({get, set}, data, a) => {
    const inputData = get(inputDataState);
    set(inputDataState, produce(inputData, (draft) => {
      if (!draft.pricing) draft.pricing = {};
      draft.pricing = {...draft.pricing, ...data};
    }))
  }
})

export const inputDataState = atom({
  key: 'pricing_input_data',
  default: defaultInputData,
});

export const zipcodeListState = selector({
  key: 'zipcode_csv_state',
  get: ({get}) => { 
    const inputData = get(inputDataState);
    // parse object => array
    let zipcodeData = inputData.zipcode_list;
    if (zipcodeData) {
      zipcodeData = Object.keys(zipcodeData).map((key) => ({zipcode: key, quantity: zipcodeData[key]}));
    }
    return zipcodeData;
  },
  set: ({get, set}, value) => {
    const inputData = get(inputDataState);
    if (value) {
      set(inputDataState, produce(inputData, (draft) => {
        draft.zipcode_list = value.reduce((obj, item) => (obj[item.zipcode] = item.quantity, obj) ,{});
      }));
    }
  }
});

export const useZipCodeCSVState = selector({
  key: 'use_zipcode_csv_state',
  get: ({get}) => { 
    const inputData = get(inputDataState);
    return inputData.use_zipcode_csv;
  },
  set: ({get, set}, value) => {
    const inputData = get(inputDataState);
    set(inputDataState, produce(inputData, (draft) => {
      draft.use_zipcode_csv = value;
      if (value) {
        draft.use_zipcode = false;
      }
    }));
  }
});

export const validInputDataState = selector({
  key: 'pricing_valid_input_data',
  get: ({get}) => {
    const {address, radius, vehicle_capacity, use_zipcode_csv, zipcode_list} = get(inputDataState);
    let valid = true;

    if (!address || !address.lat || !address.lng) {
      valid = false;
    }

    if (!radius || !vehicle_capacity) {
      valid = false;
    }
    if (use_zipcode_csv) {
      if (_.isEmpty(zipcode_list)) {
        valid = false;
      }
    } else {
      for (let i = 0; i < radius.length; i++) {
        if (i > 0 && !!radius[i] && radius[i] < radius[i - 1]) {
          valid = false;
        }
      }
    }
    return valid;
  }
})

export const zipcodesState = atom({
  key: '',
  default: [],
})

export const zipcodesDataState = selector({
  key: 'zipcodes_data',
  get: ({get}) => {
    return get(zipcodesState);
  },
  set: ({get, set}, data) => {
    const inputData = get(inputDataState);
    const zipcodeList = data.map(zipcode =>
      zipcode.filter(z => !!z && !!z.properties)
        .map(z => z.properties.zipcode)
        .filter(zip => !!zip)
    );

    set(zipcodesState, data);
    set(inputDataState, {...inputData, zipcodes: zipcodeList});
  }
})

export const createSimulationRoutingParamsState = selector({
  key: 'pricing_createSimulationRoutingParams',
  get: ({ get }) => {
    const inputData = get(inputDataState);
    const zipcodes = get(zipcodesState);
    const shipmentsParams = {vehicle_capacity: inputData.vehicle_capacity, instance: inputData.instance};

    shipmentsParams.simulation_id = inputData.id;
    shipmentsParams.pickup = inputData.address;
    shipmentsParams.client_id = inputData.client_id;
    shipmentsParams.pricing = inputData.pricing;
    shipmentsParams.use_zipcode = inputData.use_zipcode;
    shipmentsParams.use_zipcode_csv = inputData.use_zipcode_csv;
    shipmentsParams.zones = [];
    if (inputData.zipcode_list) {
      shipmentsParams.zipcode_list = inputData.zipcode_list;
    }
    inputData.radius.forEach((radius, idx) => {
      const zone = {};
      if (radius === 0) {
        shipmentsParams.zones.push({});
        return;
      }

      if (idx === 0) {
        zone.r1 = 0;
        zone.r2 = radius || 0;
      } else {
        if (shipmentsParams.zones[idx - 1].r2 === radius) {
          shipmentsParams.zones.push({});
          return;
        }
        zone.r1 = inputData.radius[idx - 1] || 0;
        zone.r2 = radius || 0;
      }
      zone.no_of_shipments = inputData.zone_shipments[idx] || 0;
      if (!!zipcodes[idx]) {
        zone.zipcodes = zipcodes[idx].filter(z => !!z && !!z.properties).map(z => z.properties.zipcode).filter(zip => !!zip);
      }
      shipmentsParams.zones.push(zone);
    });
    shipmentsParams.zones = shipmentsParams.zones.filter((zone) => !_.isEmpty(zone));

    return shipmentsParams;
  },
});

export const problemsStatisticState = selector({
  key: 'pricing_problems_statistic',
  get: ({get}) => {
    const routingProblems = get(routingProblemsState);
    if (!routingProblems || routingProblems.length < 1) return [];

    const rps = routingProblems.map((routingProblem, i) => {
      const title = routingProblem.routing_problem_id.substr(-4).toUpperCase();
      const result = {
        id: routingProblem.routing_problem_id,
        'title': `Problem ${title}`,
        'total_route': 'N/A',
        'total_distance': 'N/A',
        'total_price': 'N/A',
        'total_shipment': 'N/A'
      };

      if (routingProblem.solution && routingProblem.solution.routes && routingProblem.solution.routes.length > 0) {
        result['total_route'] = routingProblem.solution.routes.length;
        result['total_shipment'] = routingProblem.solution.routes.reduce((a, b) => a + (b.stops.length || 0), 0);
        result['total_distance'] = (routingProblem.solution.routes.reduce((a, b) => a + (b.cost.travel_distance || 0), 0) / 1609.34).toFixed(2);
        result['total_price'] = (routingProblem.solution.routes.reduce((a, b) => a + (b.cost && b.cost.extra_cost ? b.cost.extra_cost.driver_payment : 0), 0)).toFixed(2);        
        result['package_cost'] = ((routingProblem.solution.routes.reduce((a, b) => a + (b.cost && b.cost.extra_cost ? b.cost.extra_cost.driver_payment : 0), 0)) / routingProblem.solution.routes.reduce((a, b) => a + (b.stops.length || 0), 0)).toFixed(2);
      }

      return result;
    });

    const activeProblems = rps.filter(rp => rp.total_route !== 'N/A');
    if (!activeProblems || activeProblems.length <= 0) {
      return rps;
    }

    const nActiveRoutes = activeProblems.length;
    const avg = {
      id: 'avg',
      title: 'Avg',
      total_shipment: activeProblems.reduce((a, b) => a + (b.total_shipment || 0), 0)/nActiveRoutes,
      total_distance: (activeProblems.reduce((a, b) => a + (parseFloat(b.total_distance) || 0), 0)/nActiveRoutes).toFixed(2),
      total_price: (activeProblems.reduce((a, b) => a + (parseFloat(b.total_price) || 0), 0)/nActiveRoutes).toFixed(2),
      total_route: (activeProblems.reduce((a, b) => a + (parseFloat(b.total_route) || 0), 0)/nActiveRoutes).toFixed(2),
      package_cost: (activeProblems.reduce((a, b) => a + (parseFloat(b.package_cost) || 0), 0)/nActiveRoutes).toFixed(2),
    }

    return rps.concat([avg]);
  }
});

export const routingProblemSolutionState = selector({
  key: 'pricing_routing_problem_solution',
  set: ({get, set}, solution) => {
    const routingProblems = get(routingProblemsState);
    const newRoutingProblems = routingProblems.map(rp => {
      if (rp.routing_problem_id && rp.routing_problem_id === solution.problem_id) {
        return produce(rp, (draft) => {
          draft.solution = solution;
        })
      }

      return rp;
    })

    set(routingProblemsState, newRoutingProblems);
  }
});

export const simulatingSelectorState = selector({
  key: 'pricing_simulating_selector',
  get: ({get}) => {
    const simulating = get(simulatingState);
    if (!simulating) return false;
    const values = Object.values(simulating);    

    if (values && values.length > 0 && values.filter(v => !!v).length > 0) {
      return true;
    }

    return false;
  },
  set: ({get, set}, {routing_problem_id, value}) => {
    set(simulatingState, produce(get(simulatingState), (draft) => {
      draft[routing_problem_id] = value;
    }))
  }
});
